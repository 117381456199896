import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import JoycordChannels from "./JoycordChannels";
import reportWebVitals from "./reportWebVitals";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { SearchContextProvider } from "./store/search-context";


const router = createBrowserRouter([
  {
    path: "/",
    element: <SearchContextProvider><App /></SearchContextProvider>,
  },
  {
    path: "/se-ne-parla-qui/:selectedGameIdUrl/:searchInputUrl",
    element: <SearchContextProvider><App /></SearchContextProvider>,
  },
  {
    path: "/joycord/channels",
    element: <JoycordChannels />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
